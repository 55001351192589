import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const IntegrateJira = () => {
  return (
    <LayoutComponent>
      <h4>Integrate with JIRA</h4>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          Only the organization administrator can integrate Jira with GAINSS.
          Other users do not have the permissions to perform this action.
        </p>
      </div>
      <div>
        <p>
          If you haven't integrated a project management tool yet, follow the
          steps below to connect Jira to GAINSS.
        </p>

        <ol>
          <li>Log in to your GAINSS account.</li>
          <li>
            Navigate to the <strong>Account Menu</strong> at the top right
            corner of the screen.
          </li>
          <li>
            Select <strong>Integrations</strong> from the dropdown menu.
          </li>
          <li>
            On the integrations page, click on <strong>Connect to Jira</strong>.
          </li>
          <li>
            You will be redirected to Jira's authorization page. Follow the
            prompts to allow GAINSS access to your Jira site.
          </li>
          <li>After granting access, you will be redirected back to GAINSS.</li>
          <li>
            Select the Jira site you want to connect with GAINSS from the{" "}
            <strong>Site Selection</strong> dropdown.
          </li>
          <li>
            Click <strong>Continue</strong> to complete the integration.
          </li>
        </ol>

        <p>
          Once connected, GAINSS will be able to access your Jira projects and
          you can begin managing issues through the platform.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default IntegrateJira
